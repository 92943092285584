<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'payment/list'"
          :headers="tableHeaders"
          :title="tableTitle"
          :itemsPerPageSent="itemsPerPageSent"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          tableType="payments"
          icon="mdi-cash-multiple"
          tableUser="customer"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
export default {
  components: {
    Table
  },
  data: function() {
    return {
      sortBy: ["date"],
      sortDesc: [true],
      itemsPerPageSent: 50
    };
  },
  computed: {
    tableTitle() {
      return this.$vuetify.lang.t("$vuetify.cards.incomes");
    },
    tableHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.tables.transactio_id"),
          value: "id"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.reg_id"),
          value: "company_id",
          align: " d-none"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.company"),
          value: "company.name"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.amount"),
          value: "amount"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.type"),
          value: "payment_type"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.barcode_count"),
          value: "barcode_count"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.payed_from"),
          value: "payed_from"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.payed_to"),
          value: "payed_to"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.date"),
          value: "insert_date"
        }
      ];
    }
  }
};
</script>